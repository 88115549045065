import { createStyles, makeStyles } from '@material-ui/styles'

const styles = () => createStyles({
  button: {
    // reset button styles
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    padding: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '40px',
    height: '40px',

    '&:disabled': {
      opacity: 0.5,
      cursor: 'wait',
    },
  },
  icon: {
    marginBottom: '2px',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
    }
  }
})

export default makeStyles(styles)
