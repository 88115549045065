import { Spacings } from "src/common/constants"

interface IProps {
  pages: number
  words: number
  spacing?: Spacings
}

const SPACING_WORDS_COUNT = {
  [Spacings.DOUBLE]: 275,
  [Spacings.SINGLE]: 550,
  [Spacings.ONE_AND_HALF]: 400,
}

export const pagesWordsSpacingCount = ({
  pages,
  words,
  spacing,
}: IProps) => {
  if (spacing === Spacings.DOUBLE || spacing === Spacings.SINGLE || spacing === Spacings.ONE_AND_HALF) {
    if (pages && !words) {
      return {
        pagesCount: pages,
        wordsCount: Math.ceil(pages * SPACING_WORDS_COUNT[spacing]),
      }
    }

    if (words && !pages) {
      return {
        pagesCount: Math.ceil(words / SPACING_WORDS_COUNT[spacing]),
        wordsCount: words,
      }
    }
  }

  return {
    pagesCount: pages,
    wordsCount: words,
  }
}