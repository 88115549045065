import { FC } from 'react'

import { IOrder } from 'src/common/interfaces'

import useStyles from './styles'
import { pagesWordsSpacingCount } from './pages-words-spacing-count'

const OrderDetailsList: FC<IOrder> = ({
  topic,
  subject,
  pages,
  words,
  deadline,
  price,
  problems,
  questions,
  spacing,
 }) => {
  const classes = useStyles()

  const { pagesCount, wordsCount } = pagesWordsSpacingCount({ pages, words, spacing })

  const arrayToMap = [
    {topic},
    {subject},
    {pages: (pages || words) ? `${pagesCount} pages / ${wordsCount} words` : null},
    {spacing},
    {problems},
    {questions},
    {deadline},
    {price: `$${price}`}
  ]

  return (
    <div className={classes.wrapper}>
      {arrayToMap.map((item) => {
        const [key, value] = Object.entries(item)[0]

        if (!value) {
          return null
        }

        return (
          <div key={key} className={classes.orderDetailsListItem}>
            <span className={classes.itemTitle}>{key}</span>
            <span className={classes.itemValue}>{value}</span>
          </div>
        )
      })}
    </div>
  )
}

export default OrderDetailsList
